import InputLabel from '@/components/common/InputLabel'
import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { styled } from 'styled-system/jsx'

const Notes = ({ register }: { register: UseFormRegister<any>, }) => {
    return (
        <FlexCol>
            <InputLabel>Notes</InputLabel>
            <Textarea {...register("notes")} />
        </FlexCol>
    )
}

export default Notes

const FlexCol = styled('div', {
    base: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxSizing: "border-box"
    }
})

const Textarea = styled('textarea', {
    base: {
        width: "100%",
        alignSelf: "center"
    }
})