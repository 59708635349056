import React, { useEffect } from 'react'
import { styled } from 'styled-system/jsx'
import { css } from 'styled-system/css';
import { Input, Unimportant } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { FormTemplateDBSchema } from 'src/features/forms/types';
import { Form, ButtonsBar } from '../styles';
import { EMTPY_SUBTASK, MIN_SUBTASKS, } from '../constants';
import TopCorner from './components/TopCorner';
import AutoInputArray from '../components/AutoInputArray';
import Collapsible from './components/Collapsible';
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod';
import Cron from './components/Cron';
import Scheduled from './components/Scheduled';
import { NewTemplateDBTask, PutTemplateDBTaskSchema, TemplateDB, } from 'src/types';
import { cloneDeep } from 'lodash';
import FormError from './components/FormError';
import { convertTemplateDBDefaultValues } from '../utils';
import { useParams, useSearchParams } from 'next/navigation';

/**
 * Form for adding/editing tasks
 * TODO: how to add default values?
 * TODO: validation: NOT TASKSCHEMA, make a schema specific for form from taskschema
 * @returns
 */
const TemplateDBTaskForm = ({ style = {}, defaultValues, handleDelete, handleSubmit: onSubmit }: {
    style?: Record<string, any>,
    defaultValues?: TemplateDB,
    handleDelete: (id: string) => void,
    handleSubmit: (item: NewTemplateDBTask) => void,
}) => {
    // const router = useRouter()
    // const { category } = router.query
    const searchParams = useSearchParams()
    // TODO: check that category is in searchParams (from dynamic route)
    const { category } = (useParams() || {}) as { category?: string[] }

    const { control, watch, handleSubmit, register, formState: { errors, isSubmitting }, setValue, getValues, reset } = useForm({
        defaultValues: {
            ...convertTemplateDBDefaultValues(cloneDeep(defaultValues), category)
        },
        resolver: zodResolver(FormTemplateDBSchema) // TODO: remove passthrough
    });
    // console.log({ errors })
    // console.log("default", defaultValues, convertTemplateDBDefaultValues(cloneDeep(defaultValues), category))

    return (
        <Form style={style} onSubmit={handleSubmit(async (values, e) => {
            const validated = PutTemplateDBTaskSchema.parse({ ...values })
            onSubmit(validated)
            // const res =TemplateDBSchema.partial({id: true, createdAt: true, updatedAt: true}).safeParse(values)
        })}>
            <div></div>
            <TopCorner watch={watch} register={register} control={control} errors={errors} />
            <Field className={css({ justifyContent: 'center', fontSize: '0.95em' })}>&#x1F522;&nbsp;Counter:
                <Input
                    {...register('counter', { valueAsNumber: true })}
                    className={css({ width: 'min-content', display: 'inline', marginInline: 12, })}
                    type="number"
                    min={0}
                    max={99}
                    id="counter"
                    size={'small'}
                />
                <FormError errors={errors} name={'counter'} />
                <FlexCol>
                    <Unimportant>Use #c to denote counter in name</Unimportant>
                    <Unimportant>input. Ex. Assignment #c</Unimportant>
                </FlexCol>
            </Field>

            <Scheduled register={register} control={control} watch={watch} errors={errors} />
            <DateArea>
                <Cron includeDatePicker control={control} errors={errors} register={register} />
            </DateArea>

            <AutoInputArray register={register} name='subtasks' control={control} watch={watch} title='Subtasks'
                empty={EMTPY_SUBTASK} minLength={MIN_SUBTASKS}
                removeCond={(x) => !x?.name} appendCond={(x) => x.name}
                properties={['name']}
                errors={errors}
            />
            <Collapsible register={register} control={control} watch={watch} errors={errors} />
            <ButtonsBar className={css({ gridArea: 'submit' })}>
                <Button id='submit' disabled={isSubmitting} name='submit' color={'success'} type='submit' >{defaultValues ? 'Save' : 'Submit'}</Button>
                {defaultValues?.id && <Button onClick={() => handleDelete(defaultValues.id)} id='delete' name='delete' color={'error'} type={'button'} >Delete</Button>}
            </ButtonsBar>
        </Form>
    )
}

export default TemplateDBTaskForm

const DateArea = styled('div', {
    base: {
        display: "flex",
        gap: 15,
        gridArea: "due"
    }
})

const Field = styled('div', {
    base: {
        display: "flex",
        alignItems: "center"
    }
})

const FlexCol = styled('div', {
    base: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxSizing: "border-box"
    }
})
