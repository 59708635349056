import { ReactNode } from 'react'
import CollapsibleComponent from 'src/components/common/Collapsible';
import { css } from 'styled-system/css';
import { styled } from 'styled-system/jsx'
import InputLabel from '@/components/common/InputLabel';
import Links from './Links';
import { Control, FieldErrorsImpl, UseFormRegister, UseFormRegisterReturn, UseFormWatch, Validate, ValidationRule } from 'react-hook-form'
import Notes from '../../components/Notes';
import GradeWeight from './GradeWeight';
import Location from './Location';

const Collapsible = ({ control, register, errors, watch, children }: {
    control: Control<any>,
    register: UseFormRegister<any>,
    watch: UseFormWatch<any>,
    errors: Partial<FieldErrorsImpl<any>>,
    children?: ReactNode
}) => {
    return (
        <CollapsibleComponent>
            <CollapseArea>
                {children}
                <GradeWeight control={control} />
                <LinkArea className={css({ fontSize: '0.95em' })} >
                    <InputLabel className={css({ gridColumn: 'span 2' })} >Links</InputLabel>
                    <Links hideLabel register={register} control={control} watch={watch} errors={errors} />
                </LinkArea>
                <Notes register={register} />
                <Location register={register} control={control} errors={errors} />
            </CollapseArea>
        </CollapsibleComponent>
    )
}

export default Collapsible

const CollapseArea = styled('div', {
    base: {
        width: "100%",
        minHeight: "max-content",
        display: "flex",
        flexDirection: "column",
        paddingInline: 30,
        paddingBlock: 10,
        position: "relative",
        boxSizing: "border-box",
        justifyContent: "center",
        color: "$primary12",
        overflow: "scroll",
        gap: 15
    }
})

const LinkArea = styled('div', {
    base: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        gap: 10
    }
})