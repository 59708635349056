import React from 'react'
import { ControlledCollapsible } from 'src/components/common/Collapsible';
import { css, cva } from 'styled-system/css';
import { styled } from 'styled-system/jsx'
import { Control, FieldErrorsImpl, useController, UseFormRegister, UseFormWatch } from 'react-hook-form'
import { PauseIcon, PlayIcon } from '@radix-ui/react-icons';
import { Input, TriangleNav, Unimportant } from '@/components/common';
import { getCronParser, nextAdd } from '../../utils';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from '../../styles';

// TODO: calculate offset in convertDefault if nextAdd already exists + errors
const Scheduled = ({ control, register, errors, watch }: {
    control: Control<any>,
    register: UseFormRegister<any>,
    watch: UseFormWatch<any>,
    errors: Partial<FieldErrorsImpl<any>>,
}) => {
    const { field } = useController({
        name: 'scheduled',
        control,
    });
    const { field: offsetField } = useController({
        name: 'cronNextAddOffset',
        control,
    });
    const { value: offset, onChange: onInc } = offsetField
    const { value: scheduled, onChange } = field
    const toggle = () => {
        onChange(scheduled ? 0 : 1)
    }

    const cronWatch = watch(['cronInfo.formatCron', 'time.0', 'cronInfo.daysOfWeek', 'date.0', 'cronInfo.rateIntervalDays', 'daysAddInAdvance'])
    const cron = getCronParser(...cronWatch)

    return (
        <ControlledCollapsible
            className={css({ gridArea: 'bar', width: '90%', backgroundColor: '$info3', })}
            open={!!scheduled} onOpenChange={toggle}
            trigger={<Bar on={!!scheduled} >
                {scheduled ? "On" : "Off"}: Automatically add on schedule
                {scheduled ? <Pause on={!!scheduled} /> : <Play on={!!scheduled} />}
            </Bar>}
        >
            <CollapseArea>
                <Field>Next add:&nbsp;
                    {(cron ? <Field className={css({ gap: 10 })}><TriangleNav direction='left' fn={() => offset > 0 && onInc(offset - 1)} />{nextAdd(cron, offset, cronWatch[5])?.toLocaleString()}
                        <TriangleNav direction='right' fn={() => onInc(offset + 1)} /><Unimportant>  approx.</Unimportant></Field>
                        : '--'
                    )}
                </Field>
                <Field>Add <Input
                    {...register('daysAddInAdvance', { valueAsNumber: true })}
                    className={css({ width: 'min-content', display: 'inline', marginInline: 12, })}
                    type="number"
                    min={0}
                    max={99}
                    id="counter"
                /> days before due</Field>
                <ErrorMessage
                    errors={errors}
                    name={'daysAddInAdvance'}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </CollapseArea>
        </ControlledCollapsible >
    )
}

export default Scheduled

const Field = styled('div', {
    base: {
        display: "flex",
        alignItems: "center"
    }
})

const playIconStyle = cva({
    base: {
        size: '26px',
        right: '5%',
        position: 'absolute',
        color: '$success8',
        transition: 'color 0.5s ease',
    },
    variants: {
        on: {
            true: {
                color: '$success9',
            }
        }
    }
})

const CollapseArea = styled('div', {
    base: {
        display: "flex",
        padding: "10px 20px",
        flexDirection: "column",
        gap: 10
    }
})

const Play = styled(PlayIcon, playIconStyle)
const Pause = styled(PauseIcon, playIconStyle)

const Bar = styled('div', {
    variants: {
        on: {
            true: {
                backgroundColor: "$info5"
            }
        }
    },
    base: {
        userSelect: "none",
        backgroundColor: "$info4",
        display: "flex",
        justifyContent: "center",
        padding: 5,
        transition: "background-color 0.5s ease",
        position: "relative",
        margin: "auto",
        alignItems: "center"
    }
})