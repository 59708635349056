import { Input } from '@/components/common'
import Select, { ContentUnit } from '@/components/common/select'
import { css } from 'styled-system/css';
import Star from '@/components/common/Star'
import useActiveQuery from '@/hooks/main/category/useActiveQuery'
import React from 'react'
import { Control, Controller, UseFormRegister, UseFormWatch } from 'react-hook-form'
import { styled } from 'styled-system/jsx'
import { ErrorText } from '../../styles'

const TopCorner = ({ control, errors, register, watch }: { control: Control<any>, errors?: any, register: UseFormRegister<any>, watch: UseFormWatch<any>, }) => {
    const { categories } = useActiveQuery()
    const id = watch('id')
    const selectData: ContentUnit[] | undefined = categories?.sections.map(s => {
        return {
            type: 'group', label: s.name, items: categories.categories[s.id].map(c => {
                return {
                    type: 'item',
                    label: c.name,
                    value: c.id,
                    colorNum: c.colorNum
                }
            })
        }
    })

    return (
        <Container>
            <TopCornerArea>
                <Controller
                    name="category"
                    control={control}
                    render={({ field: { ref, ...field } }) => {
                        return <Select
                            {...field}
                            onChange={(value) => field.onChange(value)}
                            disabled={!!id && field?.value?.split('/')?.length === 3}
                            data={selectData || []}
                            error={!!errors.category?.message}
                            allowSelectNone
                        />
                    }}
                />
                {errors.category?.message && <ErrorText>{errors.category?.message}</ErrorText>}
                <Controller
                    name="priority"
                    control={control}
                    render={({ field }) => <Star className={css({ marginTop: 10, marginRight: 10, })} value={field.value} onChange={field.onChange} />}
                />
            </TopCornerArea>
            <Input autoComplete='off' autoFocus label="&#x270F;&#xFE0F;&nbsp;&nbsp;Name" error={!!errors.name?.message} {...register("name")} >
                {errors.name?.message && <ErrorText>{errors.name?.message}</ErrorText>}</Input>
        </Container>
    )
}

export default TopCorner

const TopCornerArea = styled('section', {
    base: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gridArea: 'top',
        marginTop: 'calc(10px - {spacing.formPadding})',
        marginRight: 'calc(10px - {spacing.formPadding})',
    }
})

const Container = styled('div', {
    base: {
        all: "unset",
        position: "relative",
        display: "grid",
        gap: 20,
        gridTemplateAreas: `
            '. top'
            'input top'
        `,
        gridTemplateColumns: "1fr auto"
    }
})