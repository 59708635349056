'use client'
import { ComponentProps, ReactNode, useRef, useState } from 'react'
import { css } from 'styled-system/css';
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';
import { styled } from 'styled-system/jsx'
import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons';
import { animated, useSpring, useSpringRef } from '@react-spring/web';

export const CollapsibleRoot = styled(CollapsiblePrimitive.Root, {
    base: {
        width: "90%",
        marginInline: "auto",
        background: "$primary4",
        gridArea: "collapsible",
        position: "relative",
        minWidth: "max-content",
        zIndex: 5
    }
});
export const CollapsibleTrigger = styled(CollapsiblePrimitive.Trigger, {
    base: {
        width: '100%',
        // height: barHeight,
        flexGeneral: 'row', justifyContent: 'flex-end',
    }
})

export const TriggerContent = styled('div', {
    base: {
        border: "none",
        background: "$primary6",
        "&:hover": {
            background: "$primary7"
        }
    }
})

const ClosedIcon = styled(TriangleDownIcon, {
    base: {
        color: "$primary10"
    }
})
const OpenIcon = styled(TriangleUpIcon, {
    base: {
        color: "$primary10"
    }
})
export const CollapsibleContent = styled(animated(CollapsiblePrimitive.Content), {
    base: { background: 'inherit', size: '100%', minHeight: 'max-content', minWidth: 'max-content', boxSizing: 'border-box', overflow: 'auto' }
})

const Collapsible = ({ children, triggerText, trigger }: { children: JSX.Element, triggerText?: string, trigger?: ReactNode }) => {
    const [open, setOpen] = useState(false)
    const ref = useRef<HTMLDivElement>(null);
    const api = useSpringRef()
    const springs = useSpring({
        ref: api,
        from: { maxHeight: 0, },
    })

    const handleClick = (open: boolean) => {
        setOpen(open)
        api.start({
            to: {
                maxHeight: open ? ref.current?.getBoundingClientRect().height : 0,
            },
        })
    }

    //     <Collapsible.Trigger asChild>
    //     <Button>Show/hide categories</Button>
    // </Collapsible.Trigger>
    return (
        <CollapsibleRoot open={open} onOpenChange={handleClick} >
            <CollapsibleTrigger asChild>
                {
                    trigger || <TriggerContent>{triggerText}{open ? <OpenIcon /> : <ClosedIcon />}</TriggerContent>
                }
            </CollapsibleTrigger>
            <CollapsibleContent forceMount style={springs}>
                <div className={css({ padding: 0, margin: 0, minHeight: 'max-content', position: 'relative' })} ref={ref} >
                    {children}
                </div>
            </CollapsibleContent>
        </CollapsibleRoot>
    )
}

export default Collapsible

type Props = ComponentProps<typeof CollapsibleRoot>
export const ControlledCollapsible = ({ children, trigger, onOpenChange, ...fields }: { children: JSX.Element, trigger: JSX.Element } & Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const api = useSpringRef()
    const springs = useSpring({
        ref: api,
        from: { maxHeight: 0, },
    })

    const handleClick = (open: boolean) => {
        onOpenChange && onOpenChange(open)
        api.start({
            to: {
                maxHeight: open ? ref.current?.getBoundingClientRect().height : 0,
            },
        })
    }

    return (
        <CollapsibleRoot {...fields} onOpenChange={handleClick} >
            <CollapsiblePrimitive.Trigger asChild>
                {trigger}
            </CollapsiblePrimitive.Trigger>
            <CollapsibleContent forceMount style={springs}>
                <div className={css({ padding: 0, margin: 0 })} ref={ref} >
                    {children}
                </div>
            </CollapsibleContent>
        </CollapsibleRoot>
    )
}
