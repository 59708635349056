import React from 'react'
import { InputWrapper } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { DatePicker } from 'src/components/common/DateTimeInputs';
import InputLabel from '@/components/common/InputLabel';
import { Form, ButtonsBar, Field } from '../styles';
import { EMTPY_SUBTASK, MIN_SUBTASKS } from '../constants';
import TopCorner from './components/TopCorner';
import AutoInputArray from '../components/AutoInputArray';
import Collapsible from './components/Collapsible';
import { Control, FieldErrorsImpl, UseFormRegister, UseFormWatch, Controller, SubmitHandler } from 'react-hook-form'
import { isArray } from 'lodash';
import { NumberInput, Tooltip } from '@planda/design-system';
import { useGetUserQuery } from '@/redux/features/api/apiSlice';
import HandedOutDate from './components/HandedOutDate';
import { categoryColorDynamic } from '@/utils/categories';

/**d
 * Form for adding/editing tasks
 * TODO: go through and un-any react-hook-form props
 * @returns
 */
const TaskForm = ({ style = {}, control, watch, handleSubmit, register, errors, isSubmitting, colorNum }: {
    style?: Record<string, any>,
    control: Control<any>,
    register: UseFormRegister<any>,
    watch: UseFormWatch<any>,
    errors: Partial<FieldErrorsImpl<any>>,
    handleSubmit: SubmitHandler<any>,
    isSubmitting: boolean,
    colorNum?: number
}) => {
    // if (errors && Object.keys(errors).length > 0)
    //     console.log('taskform errors:', errors)

    const { data: user } = useGetUserQuery()

    return (
        <Form style={{ ...style, ...(colorNum !== undefined && categoryColorDynamic(colorNum)) }} onSubmit={handleSubmit}>
            <div></div>
            <TopCorner watch={watch} register={register} control={control} errors={errors} />

            <Field>
                <InputWrapper>
                    <InputLabel >&#128337;&nbsp;&nbsp;Due on</InputLabel>

                    <Controller
                        name="date.0"
                        control={control}
                        render={({ field }) => <DatePicker quickMode withTime error={isArray(errors.date) && errors.date[0]?.message} {...field} placeholder="Pick due date" />}
                    />
                    {/*
                    <InputLabel>at</InputLabel>
                    <Controller
                        name="time.0"
                        control={control}
                        render={({ field }) => <TimePicker clearable error={isArray(errors.time) && errors.time[0]?.message} {...field} />}
                    /> */}
                </InputWrapper>
            </Field>
            <AutoInputArray register={register} name='subtasks' control={control} watch={watch} title='Subtasks'
                empty={EMTPY_SUBTASK} minLength={MIN_SUBTASKS}
                removeCond={(x) => !x?.name} appendCond={(x) => x.name}
                properties={['name']}
                errors={errors}
            />
            <Field>
                <InputWrapper>
                    <InputLabel>⌛&nbsp;&nbsp;Estimated Time (hr)</InputLabel>

                    <Controller
                        name="estimatedTimeInHours"
                        control={control}
                        render={({ field }) => <NumberInput allowUndefined debounceWait={0} precision={2} min={0} max={100} {...field} />}
                    />
                    {/* TODO */}
                    {/* <Controller
                        name="estimatedTimeUnit"
                        control={control}
                        render={({ field: { ref, ...field } }) => {
                            return <Select
                                {...field}
                                onChange={(value) => field.onChange(value)}
                                disabled={!!id && field?.value?.split('/')?.length === 3}
                                data={[{ label: 'min', value: '1', type: 'item' }, { label: 'hr', value: 'hours', type: 'item' }, { label: 'days', value: 'days', type: 'item' }]}
                                error={!!errors.category?.message}
                                allowSelectNone
                            />
                        }}
                    /> */}
                </InputWrapper>
            </Field>


            <Collapsible register={register} control={control} watch={watch} errors={errors}>
                <HandedOutDate control={control} errors={errors} />
            </Collapsible>

            <ButtonsBar>
                <Button id='submit' name='submit' disabled={isSubmitting} color={'success'} type='submit' >Submit</Button>
                {user?.mode !== 'simplified' && <Button id='save-as-template' name='save-as-template' color={'gray'} >Save as Template</Button>}
                {/* <Tooltip content='Reset form' >
                    <Button id='reset' name='reset' color={'gray'} >Reset</Button>
                </Tooltip> */}
                {/* TODO: tooltip doesn't work */}
                <Tooltip content='Clear form' >
                    <Button id='clear' name='clear' color={'gray'} >Clear</Button>
                </Tooltip>
            </ButtonsBar>
        </Form>
    )
}

export default TaskForm
