import React from 'react'
import { Field } from '../../styles'
import { DatePicker, InputWrapper } from '@/components/common'
import InputLabel from '@/components/common/InputLabel'
import { Controller, FieldErrorsImpl } from 'react-hook-form'
import { useAppSelector } from '@/redux/hooks'

const HandedOutDate = ({ control, errors }: { control: any, errors: Partial<FieldErrorsImpl<any>> }) => {
    const isStudent = useAppSelector(state => state.featureAccess.plugins.student)
    if (!isStudent) return null
    return (
        <Field>
            <InputWrapper>
                <InputLabel>🖐️&nbsp;&nbsp;Handed out on</InputLabel>

                <Controller
                    name="handedOutDate"
                    control={control}
                    render={({ field }) => <DatePicker quickMode error={errors.handedOutDate?.message} {...field} placeholder="Pick date" />}
                />
            </InputWrapper>
        </Field>
    )
}

export default HandedOutDate