import React from 'react'
import { ErrorText, Field } from '../../styles'
import { Control, Controller, UseFormRegister } from 'react-hook-form'
import { Input } from '@/components/common'

const Location = ({ control, errors, register }: { control: Control<any>, errors: any, register: UseFormRegister<any>, }) => {
    const nameIsError = errors.location?.name?.message
    return (
        <Field>
            <Input label="&#x270F;&#xFE0F;&nbsp;&nbsp;Location" error={!!nameIsError} {...register("location.name")} >
                {nameIsError && <ErrorText>{nameIsError}</ErrorText>}</Input>
        </Field>
    )
}

export default Location