import InputLabel from '@/components/common/InputLabel'
import { NumberInput } from '@planda/design-system'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Field } from '../../styles'
import { InputWrapper } from 'src/components/common';

const GradeWeight = ({ control }: { control: Control<any> }) => {
    return (
        <Field>
            <InputWrapper>
                <InputLabel>📊&nbsp;&nbsp;Grading Weight</InputLabel>
                <Controller
                    name="gradingWeightInPercentage"
                    control={control}
                    render={({ field }) => (
                        // why did I do debounceWait={0} because only rely on focus change
                        <NumberInput debounceWait={0} precision={2} min={0} max={100} {...field} onChange={field.onChange} />
                    )} />
                %
            </InputWrapper>
        </Field>
    )
}

export default GradeWeight

export const ActualGrade = ({ control }: { control: Control<any> }) => {
    return (
        <Field>
            <InputWrapper>
                <InputLabel>📊&nbsp;&nbsp;Current Grade</InputLabel>
                <Controller
                    name="gradeInPercentage"
                    control={control}
                    render={({ field }) => (
                        // why did I do debounceWait={0} because only rely on focus change (NOT WORKING)
                        <NumberInput allowUndefined debounceWait={0} precision={2} min={0} max={100} {...field} onChange={field.onChange} />
                    )} />
                %
            </InputWrapper>
        </Field>
    )
}
