import { UnimportantVerticalCenter } from '@/components/common'
import Checkbox from '@/components/common/Checkbox'
import { Info } from '@planda/design-system'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

const WorkBlock = ({ control }: { control: Control<any>, }) => {
    return (
        <Controller
            name={"isWorkBlock"} // initializes empty array
            control={control}
            render={({ field }) => <Checkbox size={'small'} checked={field.value}
                onCheckedChange={field.onChange}
                id="is-work-block">
                <UnimportantVerticalCenter>Is a work block?<Info text='Can drag tasks into a work block' /></UnimportantVerticalCenter>
            </Checkbox>}
        />
    )
}

export default WorkBlock