import React, { useState } from 'react'
import { UseFormSetValue, SubmitHandler, UseFormGetValues, useController } from "react-hook-form";
import { css } from 'styled-system/css';
import { InputWrapper, Button } from 'src/components/common';
import { endOfDay, isSameMinute, startOfDay } from 'date-fns';
import InputLabel from '@/components/common/InputLabel';
import Checkbox from '@/components/common/Checkbox';
import { Group, Label } from '@radix-ui/react-context-menu';
import Collapsible from './components/Collapsible';
import TopCorner from './components/TopCorner';
import { Control, FieldErrorsImpl, UseFormRegister, UseFormWatch } from 'react-hook-form'
import { ButtonsBar, Field, Form } from '../styles';
import Optional from './components/Optional';
import { Flex } from '@planda/design-system';
import TimeRangeInput from './components/TimeRangeInput';
import DateRangeInput from './components/DateRangeInput';
import WorkBlock from './components/WorkBlock';
import Restful from './components/Restful';

/**
 * Form for adding/editing tasks
 * TODO: how to add default values?
 * TODOOOOOOO: mutliday handling
 * TODO: validation: NOT TASKSCHEMA, make a schema specific for form from taskschema
 * @returns
 */
const EventForm = ({ setValue, style = {}, control, watch, handleSubmit, register, errors, getValues, isSubmitting }: {
    setValue: UseFormSetValue<any>,
    getValues: UseFormGetValues<any>,
    style?: Record<string, any>,
    control: Control<any>,
    register: UseFormRegister<any>,
    watch: UseFormWatch<any>,
    errors: Partial<FieldErrorsImpl<any>>,
    handleSubmit: SubmitHandler<any>,
    isSubmitting: boolean
}) => {
    // errors && Object.keys(errors).length > 0 && console.log("errors", errors)

    const { field } = useController({
        name: 'isMultiDay',
        control,
    });
    const { value: isMultiDay, onChange: setMultiDay } = field

    // const [isMultiDay, setIsMultiDay] = useState(() => {
    //     const range = getValues('date')
    //     return !!range[0] && !!range[1] && !isSameDay(range[0], range[1])
    // })

    const [isAllDay, setIsAllDay] = useState(() => {
        const range = getValues('time')
        if (!range[0] || !range[1]) return false
        return isSameMinute(startOfDay(range[0]), range[0]) && isSameMinute(endOfDay(range[1]), range[1])
    })

    // const date = watch('date')
    // const priority = watch('priority')

    return (
        <Form style={style} onSubmit={handleSubmit}>
            <div></div>
            <TopCorner watch={watch} register={register} control={control} errors={errors} />

            <Field>
                <InputWrapper>
                    <InputLabel >&#128337;&nbsp;&nbsp;Duration/Date:</InputLabel>

                    <Group>
                        <DateRangeInput errors={errors} watch={watch} control={control} name={'date'} isMultiDay={isMultiDay} />

                        {!isAllDay && <Flex className={css({ marginBlock: 10 })} align={'center'} gap={'3'}>
                            <Label className={css({ margin: '7px 2px 7px 0px', textAlign: 'center', })}>from</Label>
                            <TimeRangeInput name={'time'} errors={errors} control={control} />
                        </Flex>
                        }
                    </Group>

                    <Group>

                        <Checkbox checked={isMultiDay}
                            onCheckedChange={setMultiDay}
                            id="is-multi-day">
                            Multiday
                        </Checkbox>

                        {/* <InputLabel>All day</InputLabel> */}
                        <Checkbox checked={isAllDay}
                            onCheckedChange={(checked) => {
                                if (checked) { // to isAllDay
                                    setValue('time', [startOfDay(Date.now()), endOfDay(Date.now())])
                                }
                                setIsAllDay(checked as boolean);
                            }}
                            id="is-all-day">
                            All day
                        </Checkbox>
                        <Optional watch={watch} setValue={setValue} />
                    </Group>
                </InputWrapper>
            </Field>
            <Collapsible register={register} control={control} watch={watch} errors={errors} >
                <WorkBlock control={control} />
                <Restful control={control} />
            </Collapsible>

            <ButtonsBar>
                {/* <Button id='save-as-template' name='save-as-template' color={'gray'} type={'submit'} >Save as Template</Button> */}
                <Button id='submit' name='submit' color={'success'} disabled={isSubmitting} type='submit' >Submit</Button>
                <Button id='clear' name='clear' color={'gray'} >Clear</Button>
            </ButtonsBar>
        </Form>
    )
}

export default EventForm

{/* <Controller
                            name="date"
                            control={control}
                            render={({ field: { ref, ...field } }) => {
                                console.log("field", field.value, isMultiDay)
                                return isMultiDay && <DateRangeInput {...field} value={date} placeholder="Pick event dates" error={errors.date?.message || isArray(errors?.date) && (errors.date[0]?.message || errors.date[1]?.message)} />
                            }}
                        /> */}
{/* <Controller
                                name="time.0"
                                control={control}
                                render={({ field: fieldOne }) => <>
                                    <TimePicker quickMode maw={90}
                                        error={isArray(errors.time) && errors.time[0]?.message} {...fieldOne} />
                                    <span className={css({ marginBottom: 10 })}>–</span>
                                    <Controller
                                        name="time.1"
                                        control={control}
                                        render={({ field }) => <TimeEndPicker errors={errors} field={field} timeStartField={fieldOne} />}
                                    />
                                </>}
                            /> */}
{/* –
                            <Controller
                                name="time.1"
                                control={control}
                                render={({ field }) => <TimePicker maw={80} error={isArray(errors.time) && errors.time[1]?.message} {...field}
                                    quickMode
                                    quickAddOptions={[
                                        {
                                            label: '30min',
                                            date: (date: Date | null | undefined) => addMinutes()
                                            new Date((date || new Date()).setHours(9, 30, 0, 0)),
                                            unit: 'time',
                                        },
                                        {
                                            label: '1hr',
                                            date: (date: Date | null | undefined) => new Date((date || new Date()).setHours(19, 0, 0, 0)),
                                            unit: 'time',
                                        },
                                    ]}
                                />}
                            /> */}