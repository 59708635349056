import React from 'react'
import { styled } from 'styled-system/jsx'
import { css } from 'styled-system/css';
import { UseFormSetValue, SubmitHandler, UseFormGetValues } from "react-hook-form";
import { Button } from 'src/components/common';
import { ContentUnit } from 'src/components/common/select';
import { ButtonsBar, Form } from '../styles';
import Collapsible from './components/Collapsible';
import TopCorner from './components/TopCorner';
import { Control, FieldErrorsImpl, UseFormRegister, UseFormWatch } from 'react-hook-form'
import Cron from './components/Cron';
import Duration from './components/Duration';
import StartEndDates from './components/StartEndDates';
import Optional from './components/Optional';
import WorkBlock from './components/WorkBlock';
import Restful from './components/Restful';

/**
 * Form for adding/editing tasks
 * TODO: how to add default values?
 * TODOOOOOOO: mutliday handling
 * TODO: validation: NOT TASKSCHEMA, make a schema specific for form from taskschema
 * @returns
 */
const TemplateRecurEventForm = ({ setValue, style = {}, control, watch, handleSubmit, register, errors, getValues, isSubmitting }: {
    setValue: UseFormSetValue<any>,
    getValues: UseFormGetValues<any>,
    style?: Record<string, any>,
    control: Control<any>,
    register: UseFormRegister<any>,
    watch: UseFormWatch<any>,
    errors: Partial<FieldErrorsImpl<any>>,
    handleSubmit: SubmitHandler<any>,
    isSubmitting: boolean
}) => {

    return (
        <Form style={style} onSubmit={handleSubmit}>
            <div></div>
            <TopCorner watch={watch} register={register} control={control} errors={errors} />

            <StartEndDates control={control} errors={errors} register={register} />
            <Cron label='Date:' control={control} errors={errors} register={register} />
            <Duration control={control} errors={errors} register={register} />
            <Field className={css({ marginLeft: 5 })}>
                <Optional watch={watch} setValue={setValue} />
                <WorkBlock control={control} />
                <Restful control={control} />
            </Field>
            <Collapsible register={register} control={control} watch={watch} errors={errors} />

            <ButtonsBar className={css({ gridArea: 'submit' })}>
                {/* <Button id='save-as-template' name='save-as-template' color={'gray'} type={'submit'} >Save as Template</Button> */}
                <Button id='submit' name='submit' disabled={isSubmitting} color={'success'} type='submit' >Submit</Button>
            </ButtonsBar>
        </Form>
    )
}

export default TemplateRecurEventForm

const Field = styled('div', {
    base: {
        display: "flex",
        gap: 15
    }
})

const Textarea = styled('textarea', {
    base: {
        width: "100%",
        alignSelf: "center"
    }
})

const SubtaskArea = styled('div', {
    base: {
        position: "relative",
        gridArea: "subtasks",
        flexGeneral: "column",
        gap: 12
    }
})

const CollapseArea = styled('div', {
    base: {
        width: "100%",
        minHeight: "max-content",
        display: "flex",
        flexDirection: "column",
        paddingInline: 30,
        paddingBlock: 10,
        position: "relative",
        boxSizing: "border-box",
        justifyContent: "center",
        color: "$primary12",
        overflow: "scroll"
    }
})

const FlexCol = styled('div', {
    base: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxSizing: "border-box"
    }
})

const LinkArea = styled('div', {
    base: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        gap: 10
    }
})

// const LinkArea = styled('div', {

const categories: ContentUnit[] = [{
    type: 'item',
    label: 'item one',
    value: 'one',
},
{
    type: 'item',
    label: 'item two',
    value: 'two',
},
{
    type: 'group',
    label: 'group one',
    items: []
}]



// {/* <Controller
//     name="dateRange"
//     control={control}
//     render={({ field }) =>
//         isMultiDay ?
//             <>
//                 <Group>
//                     <DateRangeInput {...field} placeholder="Pick event dates" />
//                     {!isAllDay &&
//                         <Group>
//                             <Label className={css({ margin: '7px 2px 7px 0px', textAlign: 'center', })}>from</Label>
//                             <TimeRangePicker {...field} />
//                         </Group>
//                     }
//                 </Group>
//             </>
//             :
//             <>
//                 <Group>
//                     <DatePicker {...field} />
//                     {!isAllDay &&
//                         <>
//                             <Label className={css({ margin: '7px 2px 7px 0px', textAlign: 'center', })}>from</Label>
//                             <TimeRangePicker {...field} />
//                         </>}
//                 </Group>
//             </>}
//             */}