import { UnimportantVerticalCenter } from '@/components/common'
import Checkbox from '@/components/common/Checkbox'
import { Info } from '@planda/design-system'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

const Restful = ({ control }: { control: Control<any>, }) => {
    return (
        <Controller
            name={"isRestful"}
            control={control}
            render={({ field }) => <Checkbox size={'small'} checked={!!field.value}
                onCheckedChange={field.onChange}
                id="is-restful">
                <UnimportantVerticalCenter>Is restful?<Info text='Restful events will not be counted in productive hours' /></UnimportantVerticalCenter>
            </Checkbox>}
        />
    )
}

export default Restful