'use client'
import { zodResolver } from "@hookform/resolvers/zod";
import useTemplateDBs from "@/hooks/main/category/useTemplateDBs";
import { useInternalExternalState } from "@/hooks/useInternalExternalState";
import { createItemFromTemplateDB } from "@/utils/item";
import EventCronParser from "event-cron-parser";
import { groupBy, isNil } from "lodash";
import { Dispatch, memo, SetStateAction, useEffect, useRef, useState } from "react";
import { useController, useForm } from "react-hook-form";
import Dialog from "src/components/common/dialog";
import Tabs from "src/components/common/Tabs";
import { superRefineEventDates, TodoItemMerge, NewTemplateDBTask, NewItem, PutTaskSchema, PutTemplateRecurSchema, PutEventSchema, TodoItemType, TodoItem } from "src/types";
import { FormItem, FormItemSchema } from "../types";
import { getDefaultItemFormValues } from "../utils";
import EventForm from "./Event";
import TaskForm from "./Task";
import TemplateDBTaskForm from "./TemplateDBTask";
import TemplateRecurEventForm from "./TemplateRecurEvent";
import { ColorDiv } from "../styles";
import { Toast, ToastDescription, ToastTitle, } from "@/components/common/toast";
import { prettyDate } from "@planda/utils";
import { useGetUserQuery } from "@/redux/features/api/apiSlice";
import { useParams } from "next/navigation";
import { categoryColorDynamic } from "@/utils/categories";
import { usePutPlannerItem } from "@/hooks/main/category/usePutPlannerItem";

// Dialog, react hook form, inputs, yup

/**
 *
 * @param param0
 * @returns
 */
// MAX of 4 templates per category? change category to see all templates of that category, due date shown will always be in future, not past
const ItemForm = ({ submitCallback, children: trigger, defaultTab, open: openExternal, setOpen: setOpenExternal, defaultValues: defaultValuesGiven, defaultType }: {
    submitCallback?: (data: any, promise: Promise<any>) => any,
    children?: JSX.Element,
    defaultTab?: string[],
    open?: boolean,
    setOpen?: (x: boolean) => void,
    defaultValues?: Partial<TodoItemMerge>,
    resetFormOnOpen?: boolean,
    defaultType?: TodoItemType
}) => {
    const defaultValues = { type: defaultType, ...defaultValuesGiven }
    const { internalState: open, setInternalState: setOpen } = useInternalExternalState<boolean>(false, openExternal, setOpenExternal)

    const { category } = (useParams() || {}) as { category?: string[] }
    const { data: user } = useGetUserQuery()
    const { putItem: put } = usePutPlannerItem()

    const { items: templateDBs, parentIdDict, categories, createItemContextMenu, remove, edit } = useTemplateDBs('')
    // const arr = templateDBs?.map(templateDB => ({ templateDB: templateDB, item: createItemFromTemplateDB(templateDB) }))
    const templates = templateDBs && groupBy(templateDBs.map(templateDB => ({ templateDB, item: createItemFromTemplateDB(templateDB) })), x => x.item.type)
    // TODO need to add defaults to templates

    const [openToast, setOpenToast] = useState(false)
    const itemInfoRef = useRef({ dateStart: new Date() as Date | null, name: '', type: 'event' as 'event' | 'task' });
    const timerRef = useRef(0);

    function handleToastForNewItem(item: TodoItem) {
        window.clearTimeout(timerRef.current);
        timerRef.current = window.setTimeout(() => {
            itemInfoRef.current = { name: item.name, dateStart: isNil(item.dateStart) ? null : new Date(item.dateStart), type: item.type };
            setOpenToast(true);
        }, 500);
    }
    const onSubmit = async (data: any, promise: Promise<any>) => {
        submitCallback && submitCallback(data, promise)
        resetForm(defaultValues)
        setOpen(false)
        await promise
        if (data.type === 'event' || data.type === 'task') handleToastForNewItem(data)
    }

    //#region tab control
    const initTabTemplate = (defaultValues.type && 'default') || (defaultTab && (defaultTab.length > 1 || undefined) && defaultTab[1]) || 'default'
    const [tabTask, setTabTask] = useState(initTabTemplate)
    // const [tabEventItem, setTabEventItem] = useState(initTabTemplate)
    // const [tabTemplateDB, setTabTemplateDB] = useState(initTabTemplate)
    const [tabTemplateItem, setTabTemplateItem] = useState('default')
    //#endregion tab control

    const { control, watch, handleSubmit, register, formState: { errors, isSubmitting }, setValue, getValues, reset } = useForm<FormItem>({
        defaultValues: getDefaultItemFormValues(defaultValues, parentIdDict, category), // TODO: should i be using init or tabType?
        resolver: zodResolver(FormItemSchema) // converted normally
    });

    useEffect(() => {
        setTabTask(initTabTemplate)
        setTabTemplateItem('default')
        resetForm(defaultValues)
    }, [open])

    function resetForm(values: any) {
        const defaultValues = getDefaultItemFormValues(values, parentIdDict, category)
        reset(defaultValues)
    }

    // errors && Object.keys(errors).length > 0 && console.log("errors", errors)
    async function handleSubmitTemplateDB(item: NewTemplateDBTask) {
        const promise = put(item)
        await onSubmit(item, promise)
    }

    async function handleSubmitItem(values: FormItem, e: any) {
        const schema = values.type === 'task' ? PutTaskSchema : values.type === 'event' ? PutEventSchema.superRefine(superRefineEventDates) : PutTemplateRecurSchema
        const item = schema.parse(values)

        if (item.dateStart && item.dateIsTentative) {
            item.dateIsTentative = false
        }

        switch (e?.nativeEvent.submitter?.name) {
            case 'save-as-template': {
                // creating templateDB form item
                let cron: string | undefined = undefined
                if (item.dateStart) {
                    const date = new Date(item.dateStart)
                    const cronParser = new EventCronParser("0 0 ? *  *")
                    cronParser.setUTCHours([date.getUTCHours()], [date.getUTCMinutes()])
                    cronParser.setDaysOfWeek([date.getUTCDay() + 1], 'utc')
                    cron = cronParser.getCron()
                }
                delete item.id
                const templateDB = {
                    ...item,
                    counter: 0,
                    scheduled: 0,
                    cron,
                    type: 'templateDB' as const,
                    childType: item.type
                }
                put(templateDB as NewItem)
                return
            }
            case 'clear': {
                resetForm(defaultValues)
            }
        }

        if (e?.nativeEvent.submitter?.name !== 'submit') {
            return
        }

        if (path0 === 'task' && tabTask.startsWith('i.templateDB.task')) {
            const tdbItem = templates?.task.find(x => x.templateDB.id === tabTask)?.templateDB
            if (tdbItem) {
                edit(tabTask, { counter: tdbItem.counter + 1 }) // ?????
            }
        }

        const promise = put(item as NewItem) // vs put
        await onSubmit(item, promise) // onsubmit can call mutate
    }

    const watchCategory = watch("category")
    const colorNum = (!!watchCategory && watchCategory !== '/') ?
        categories?.categories[
            watchCategory.split('/')[0] as string
        ]?.find(x => x.id.startsWith(watchCategory))?.colorNum || undefined : undefined

    const { field: pathField } = useController({
        name: 'formPath',
        control,
    });

    const { value: path, onChange: setPath } = pathField
    const [path0, path1] = path.split('.')

    return (
        <>
            <Dialog overlayBrightness="medium-light" open={open} setOpen={setOpen} trigger={trigger} hasPadding={false} >
                <Tabs
                    onValueChange={(x) => {
                        setPath(x === 'event' ? ('event.' + (path1 || 'event')) : x)
                    }} // templateDB shouldn't matter cuz different form
                    value={path0}
                    disabled={!!defaultValues.id}
                    tabs={[{
                        value: 'task',
                        label: 'Task',
                        content: <Tabs
                            value={tabTask}
                            // onValueChange={setTabTask}
                            size={1}
                            color={'gray'}
                            disabled={!!defaultValues.id}
                            onValueChange={(value) => {
                                setTabTask(value)
                                if (value.startsWith('i.templateDB.')) {
                                    resetForm({ ...templates?.task?.find(x => x.templateDB.id === value)?.item })
                                } else {
                                    resetForm({ type: 'task' })
                                }
                            }}
                            tabs={[
                                {
                                    value: 'default',
                                    label: 'default',
                                    content: <TaskForm
                                        colorNum={colorNum}
                                        isSubmitting={isSubmitting}
                                        style={colorNum ? categoryColorDynamic(colorNum) : undefined}
                                        handleSubmit={handleSubmit(async (values, e) => handleSubmitItem({ ...values }, e))}
                                        register={register} control={control} watch={watch} errors={errors}
                                    />
                                },
                                ...(templates?.task || []).map(({ templateDB, item }, i) => {
                                    return ({
                                        label: item.name,
                                        value: templateDB.id,
                                        contextMenu: createItemContextMenu(templateDB, () => {
                                            setPath('templateDB.task')
                                            setTabTemplateItem(templateDB.id)
                                        }), // change tabs
                                        content: <TaskForm
                                            isSubmitting={isSubmitting}
                                            handleSubmit={handleSubmit(async (values, e) => handleSubmitItem({ ...values }, e))}
                                            register={register} control={control} watch={watch} errors={errors}
                                        />
                                    })
                                })
                            ]}
                        />
                    },
                    {
                        value: 'event',
                        label: 'Event',
                        content: <Tabs
                            size={1}
                            color={'gray'}
                            // defaultValue={tabTemplateDB}
                            disabled={!!defaultValues.id}
                            value={path1}
                            onValueChange={(x) => setPath('event.' + x)}
                            tabs={[
                                {
                                    value: 'event',
                                    label: 'One time',
                                    content: <EventForm
                                        getValues={getValues}
                                        isSubmitting={isSubmitting}
                                        setValue={setValue}
                                        handleSubmit={handleSubmit(async (values, e) => handleSubmitItem({ ...values }, e))}
                                        register={register} control={control} watch={watch} errors={errors}
                                    />
                                },
                                {
                                    value: 'templateRecur',
                                    label: 'Recurring',
                                    content: <TemplateRecurEventForm
                                        getValues={getValues}
                                        isSubmitting={isSubmitting}
                                        setValue={setValue}
                                        handleSubmit={handleSubmit(async (values, e) => handleSubmitItem({ ...values }, e))}
                                        register={register} control={control} watch={watch} errors={errors}
                                    />
                                },
                            ]}
                        />
                    },
                    {
                        value: 'templateDB',
                        label: 'Templates',
                        content: <Tabs
                            size={2}
                            color={'gray'}
                            value={'task'}
                            disabled={!!defaultValues.id}
                            tabs={[
                                {
                                    value: 'task',
                                    label: 'Task',
                                    content: <Tabs
                                        size={1}
                                        color={'gray'}
                                        value={tabTemplateItem}
                                        // defaultValue={'default'}
                                        onValueChange={(value) => {
                                            setTabTemplateItem(value)
                                            setTabTask(value)
                                            // templateDB.id
                                            // if (value.startsWith('i.templateDB.')) {
                                            //     // resetForm({ ...templates?.task?.find(x => x.templateDB.id === value)?.item }, false)
                                            // } else {
                                            //     // resetForm({ type: 'task' })
                                            // }
                                        }}
                                        disabled={!!defaultValues.id}
                                        tabs={[
                                            {
                                                value: 'default',
                                                label: '+',
                                                content: <TemplateDBTaskForm
                                                    handleSubmit={handleSubmitTemplateDB}
                                                    handleDelete={remove}
                                                    // @ts-expect-error i know this is wrong but i don't care, hopefully nothing will break (i think it's fine since zod strips stuff)
                                                    defaultValues={defaultValues}
                                                />
                                            },
                                            ...(templates?.task || []).map(({ templateDB, item }, i) => {
                                                return ({
                                                    label: item.name,
                                                    value: templateDB.id,
                                                    content: <TemplateDBTaskForm handleSubmit={handleSubmitTemplateDB} handleDelete={remove} defaultValues={templateDB} />
                                                })
                                            })
                                        ]}
                                    />
                                },
                                {
                                    value: 'event',
                                    label: 'Event',
                                    content: <Tabs
                                        size={1}
                                        color={'gray'}
                                        value={tabTemplateItem}
                                        onValueChange={setTabTemplateItem}
                                        disabled
                                        tabs={[
                                            {
                                                value: 'default',
                                                label: '+',
                                                content: <ColorDiv size={'large'} num={2} >Coming soon...</ColorDiv>
                                            },
                                            // ...(templates?.event || []).map(({ templateDB, item }, i) => {
                                            //     return ({
                                            //         label: item.name,
                                            //         value: templateDB.id,
                                            //         content: <EventForm handleSubmitItem={handleSubmitItem} defaultValues={item} onSubmit={onSubmit} />
                                            //     })
                                            // })
                                        ]}
                                    />
                                }
                            ]}
                        />
                    }
                    ].filter(x => {
                        if (user?.mode !== 'simplified') return true
                        return x.value !== 'templateDB'
                    })}
                />
            </Dialog>
            <Toast open={openToast} onOpenChange={setOpenToast}>
                <ToastTitle>{defaultValues.id ? 'Edited' : itemInfoRef.current.type === 'event' ? 'Scheduled' : 'Added'}: {itemInfoRef.current.name}</ToastTitle>
                <ToastDescription asChild>
                    {itemInfoRef.current.dateStart ?
                        <time dateTime={itemInfoRef.current.dateStart.toISOString()}>
                            {prettyDate(itemInfoRef.current.dateStart)}
                        </time> :
                        <div>Date: N/A</div>
                    }
                </ToastDescription>
                {/* <ToastAction asChild altText="Goto schedule to undo">
                    <Button variant="green" size="small">
                        Undo
                    </Button>
                </ToastAction> */}
            </Toast>
        </>
    )
}

export default memo(ItemForm)