import useActive from "./useActive";
import { isTemplateDB, TemplateDB } from "src/types";

/**
 * data should be Item[]
 * what data includes depends on router
 * @returns 
 */
export default function useTemplateDBs(path?: string) {
    const props = useActive('templateDB', path)

    const togglePlay = (id: string, scheduled: 1 | 0) => {
        props.edit(id, { scheduled })
    }

    return {
        ...props,
        items: props.items as TemplateDB[] | undefined,
        togglePlay,
    }
}
