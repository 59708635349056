import { Unimportant } from '@/components/common'
import Checkbox from '@/components/common/Checkbox'
import React from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

const Optional = ({ watch, setValue, }: { watch: UseFormWatch<any>, setValue: UseFormSetValue<any> }) => {
    const priority = watch('priority')
    return (
        <Checkbox size={'small'} checked={!priority}
            onCheckedChange={(checked) => {
                if (checked) setValue('priority', 0)
                else setValue('priority', 1)
            }}
            id="priority-0">
            <Unimportant>Is optional event?</Unimportant>
        </Checkbox>
    )
}

export default Optional